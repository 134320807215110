<template>
  <lay-layout  v-user-no-select>
    <template v-if="loginSuccess">
      <lay-header style="height: 101px;background-color: #efefef" >
        <TopBar :title="title + version"  @logout="logout"></TopBar>
        <lay-row >
          <lay-col md="16">
            <div style="height: 58px;line-height: 58px;padding-left: 10px">
              <lay-row space="6">
                <lay-col md="8">
                  <lay-input allowClear v-model="search.no" @keyup.enter="searchDT" @clear="searchDT">
                    <template #prepend>报告编号</template>
                  </lay-input>
                </lay-col>
                <lay-col md="8">
                  <lay-input allowClear v-model="search.manufacturer" @keyup.enter="searchDT" @clear="searchDT">
                    <template #prepend>生产厂名称</template>
                  </lay-input>
                </lay-col>
                <lay-col md="8">
                  <lay-date-picker v-model="search.test_date"  placeholder="选择检验日期" allowClear simple readonly style="width: 100%"></lay-date-picker>
                </lay-col>
              </lay-row>
            </div>
          </lay-col>
          <lay-col md="8">
            <div style="height: 55px;line-height: 55px;float: left;padding-left: 20px">
              <lay-button  size="sm" type="normal" radius @click="searchDT" ><lay-icon type="layui-icon-search" style="font-size: 12px"></lay-icon>&nbsp;搜索</lay-button>
            </div>
            <div style="height: 58px;line-height: 58px;text-align: right;padding-right: 50px">
              <lay-button  size="sm" type="primary" radius @click="newCodeReport"><lay-icon type="layui-icon-add-one" style="font-size: 12px"></lay-icon>新建出厂报告</lay-button>
            </div>
          </lay-col>
        </lay-row>
      </lay-header>
      <lay-body style="background-color: #efefef">
        <lay-scroll :height="autoHeight"  thumbColor="#e2e2e2">
          <lay-row >
            <lay-col md="24">
              <div style="padding: 0 10px">
                <lay-loading :type="2" :loading="loading" tip="加载中...">
                  <lay-table :columns="columns" :dataSource="dataSource" :page="page" even size="sm" @change="change" >
                    <template #operator="{row}">
                      <div style="text-align: center;width: 100%">
                        <lay-button type="primary" size="sm" radius @click="editLayer = true; isEdit = true; isPreview = true;selectCurrentData(row)"><lay-icon type="layui-icon-edit" style="font-size: 12px"></lay-icon>编辑</lay-button>
                      </div>
                    </template>
                    <template #code="{row}">
                      <div style="text-align: center;width: 100%">
                        <lay-button  size="sm" radius fluid @click="readOnlyLayer = true;selectCurrentData(row)"><lay-icon type="layui-icon-file" ></lay-icon></lay-button>
                      </div>
                    </template>
                  </lay-table>
                </lay-loading>
              </div>
            </lay-col>
          </lay-row>
        </lay-scroll>
      </lay-body>
    </template>
    <template v-else>
      <lay-body>
        <div :style="{'height': fullHeight}"  class="login-bg">
          <lay-row>
            <lay-col md="8" md-offset="8">
              <lay-card style="margin-top: 200px">
                <template v-slot:title >
                  <h3 style="text-align: center;letter-spacing: 3px">用户登录</h3>
                </template>
                <template v-slot:body>
                  <lay-form ref="loginRef" :model="loginInfo" pane>
                    <lay-form-item label="用户名" prop="username" :required="true" label-width="150">
                      <lay-input v-model="loginInfo.username" allow-clear placeholder="请输入用户名" @keyup.enter="loginAction"></lay-input>
                    </lay-form-item>
                    <div style="height: 20px"></div>
                    <lay-form-item label="密码" prop="password" :required="true" label-width="150">
                      <lay-input v-model="loginInfo.password" :password="true" allow-clear  type="password" placeholder="请输入密码" @keyup.enter="loginAction"></lay-input>
                    </lay-form-item>
                  </lay-form>
                  <div style="height: 20px"></div>
                  <lay-ripple type="out" trigger="mouseenter" borderRadius="2px" color="#009688" style="width: 100%" :center="true">
                    <lay-button fluid type="normal" @click="loginAction">登录</lay-button>
                  </lay-ripple>
                </template>
                <template #footer>
                  <p style="text-align: center;letter-spacing: 1px;color: #999">出厂报告管理系统{{version}}</p>
                </template>
              </lay-card>
            </lay-col>
          </lay-row>
        </div>
      </lay-body>
    </template>
    <lay-footer style="height: 40px;">
      <div style="text-align: center;">{{copyright}}</div>
    </lay-footer>
  </lay-layout>

  <lay-layer v-model="editLayer" :shade="true" :shadeClose="false" :area="['800px', '700px']" :btn="reportUploadAction" title="出厂二维码报告" @close="isEdit=false">
    <div style="padding: 20px;">
      <lay-form :model="pdf" :pane="true" ref="formRef">
        <lay-row >
          <lay-col md="12" md-offset="6">
            <div style="padding-top: 10px; width: 320px;margin: 0 auto">
              <lay-upload @done="uploaded" @error="uploadError"  :drag="true" :acceptMime="'application/pdf'"  :headers="{'access_token': access_token}" dragText="点击上传出厂报告，或将文件拖拽到此处"  :beforeUpload="beforeUpload" :disabledPreview="true" :url="ORIGIN + 'upload/image'" >
                <template #preview>
                  <div style="margin-top: -115px;margin-left: 115px" v-if="isPreview">
                    <lay-icon type="layui-icon-file" color="#009688" style="font-size: 100px"></lay-icon>
                  </div>
                </template>
              </lay-upload>
            </div>
          </lay-col>
        </lay-row>
        <lay-form-item label="出厂编号" prop="no" :required="true" label-width="150">
          <lay-input v-model="pdf.no" :allow-clear="true" ref="noInputRef" :disabled="isEdit"></lay-input>
        </lay-form-item>
        <lay-form-item label="柜体名称" prop="name" :required="true" label-width="150">
          <lay-input v-model="pdf.name" :allow-clear="true" ></lay-input>
        </lay-form-item>
        <lay-form-item label="生产厂名称" prop="manufacturer" :required="true" label-width="150">
          <lay-input v-model="pdf.manufacturer" :allow-clear="true"></lay-input>
        </lay-form-item>
        <lay-form-item label="检验日期" prop="test_date" :required="true" label-width="150">
          <lay-date-picker v-model="pdf.test_date" placeholder="选择检验日期" allowClear simple readonly style="width: 100%"></lay-date-picker>
        </lay-form-item>
        <lay-form-item label="备注{可选}" prop="remark" :required="false" label-width="150">
          <lay-textarea placeholder="请输入备注信息" v-model="pdf.remark" :allow-clear="true"></lay-textarea>
        </lay-form-item>
      </lay-form>
    </div>
  </lay-layer>
  <lay-layer v-model="readOnlyLayer" :shade="true" :shadeClose="false" :area="['800px', '815px']"  title="二维码导出【只读】" >
    <div style="padding: 20px;">
      <lay-form :model="pdf" :pane="true">
        <lay-form-item label="出厂编号" prop="no" :required="true" label-width="150">
          <lay-input v-model="pdf.no" :allow-clear="true" ref="noInputRef" disabled></lay-input>
        </lay-form-item>
        <lay-form-item label="柜体名称" prop="name" :required="true" label-width="150">
          <lay-input v-model="pdf.name" :allow-clear="true" disabled></lay-input>
        </lay-form-item>
        <lay-form-item label="生产厂名称" prop="manufacturer" :required="true" label-width="150">
          <lay-input v-model="pdf.manufacturer" :allow-clear="true" disabled></lay-input>
        </lay-form-item>
        <lay-form-item label="检验日期" prop="test_date" :required="true" label-width="150">
          <lay-date-picker v-model="pdf.test_date" placeholder="选择检验日期" allowClear simple readonly disabled style="width: 100%"></lay-date-picker>
        </lay-form-item>
        <lay-form-item label="备注{可选}" prop="remark" :required="false" label-width="150">
          <lay-textarea placeholder="请输入备注信息" v-model="pdf.remark" :allow-clear="true" disabled></lay-textarea>
        </lay-form-item>
        <lay-row >
          <lay-col md="18" md-offset="3">
            <div style="padding-bottom: 20px">
              <lay-row>
                <lay-col md="24">
                  <div style="width: 100%; padding-bottom: 15px">
                    <div style="width: 160px; height: 160px; margin: 0 auto; border: 3px solid #efefef;text-align: center;border-radius: 3px">
                      <lay-qrcode :text='"https://rpt.spepi.cn/load?id=" + pdf.no' width="150" ref="qrcodeRef" id="qrcode"></lay-qrcode>
                    </div>
                  </div>
                </lay-col>
                <lay-col md="24">
                  <div style="text-align: center">
                    <lay-quote><span id="url">{{ "https://rpt.spepi.cn/load?id=" + pdf.no}}</span>&nbsp;<lay-button id="copy" size="xs"  prefix-icon="layui-icon-link" radius>复制&nbsp;</lay-button></lay-quote>
                  </div>
                </lay-col>
                <lay-col md="11" >
                  <lay-button type="normal" size="sm" fluid radius @click="exportCodeImg" style="margin-top: 15px">导出二维码</lay-button>
                </lay-col>
                <lay-col md="11" md-offset="2">
                  <lay-button  size="sm" fluid radius @click="viewReport" style="margin-top: 15px">查看报告</lay-button>
                </lay-col>
              </lay-row>
            </div>
          </lay-col>
        </lay-row>
      </lay-form>
    </div>
  </lay-layer>
</template>
<script setup>
import TopBar from "@/components/TopBar";
import {ref, nextTick, computed, provide, reactive, watch} from "vue";
import _ from  'lodash';
import {useSize} from "@/assets/js/size";
import {useClock} from "@/assets/js/clock";
import {useUtils} from "@/assets/js/utils";
import {layer} from "@layui/layui-vue";
import axios from "axios";
import ClipboardJS from "clipboard";

axios.interceptors.request.use(function (config) {
  if (!loginSuccess.value && !config.url.includes('login')){
    return;
  }
  let access_token = localStorage.getItem("access_token");
  config.headers['access_token'] = access_token;
  config.timeout = 3500;
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  if(response.data.code == -2){
    logout(true);
    layer.msg("令牌已过期，请重新登录")
  }
  if (response.data.msg){
    layer.msg(response.data.msg);
  }
  return response;
},function (error) {
  console.log(error)
  return Promise.reject(error);
});

const {
  copyright,
  title,
  version,
  ORIGIN,
  loginInfo
} = useUtils();

const logout = (isLogout) => {
  loginSuccess.value = !isLogout;
}

const access_token = ref(localStorage.getItem("access_token"))
const loginSuccess = ref(Boolean(access_token.value));
const formRef = ref(null);
const loginRef = ref(null);
const loginAction = () => {
  loginRef.value.validate((isValidate) => {
    if (isValidate){
      axios.post(ORIGIN  + 'login', loginInfo)
          .then(res=> {
            if (res.data.code === 0) {
              loginInfo.password = '';
              access_token.value = res.data.data;
              localStorage.setItem("access_token", res.data.data);
              logout(false);
              change(page);
            }
          });
      }
  });
}

const pdf = reactive({
  no: "",
  name: '',
  manufacturer:'青岛升平电气有限公司',
  limit: -1,
  uid:1,
  count:1,
  test_date: '',
  path: '',
  remark: '',
})

const reset = () => {
  pdf.id = null;
  pdf.no = '';
  pdf.manufacturer = '青岛升平电气有限公司';
  pdf.name = '';
  pdf.test_date = '';
  pdf.path = '';
  pdf.remark = '';
}

const search = reactive({
  no: "",
  manufacturer:'',
  test_date: '',
})

watch(
    () => search.test_date,
    () => {
      searchDT()
    }
)

const page = reactive({ current: 1, limit: 16, total: 0, limits:[16], layout: ['count', 'prev', 'page', 'next', 'limits',  'refresh', 'skip'] });
const noInputRef = ref();
const editLayer = ref(false);
const readOnlyLayer = ref(false);
const isPreview = ref(false);
const loading = ref(false);
const isEdit = ref(false);
const qrcodeRef = ref()
let clipboardJS = undefined;
watch(readOnlyLayer , (newV) => {
  if (newV){
    clipboardJS = new ClipboardJS("#copy",{
      text: function() {
        return document.getElementById("url").innerText;
      }
    }).on('success', function(e) {
      layer.msg("复制成功！");
      e.clearSelection();

    }).on('error', function() {
      layer.msg("复制失败！");
    });
  }
  else {
    if (clipboardJS){
      clipboardJS.destroy();
    }
  }

})

const selectCurrentData = (row) => {
  pdf.id = row.id;
  pdf.no = row.no;
  pdf.name = row.name;
  pdf.test_date = row.test_date;
  pdf.path = row.path;
  pdf.remark = row.remark;
};

const beforeUpload = (file) => {
  isPreview.value = false;
  if(file.size > 2 * 1024 * 1024) {
    layer.msg(`文件大小超过2MB`, { icon: 2})
    return Promise.reject();
  }
  let names = _.split(file.name, '.', 1);
  let name = _.trim(names[0]);
  pdf.name = name;
  isPreview.value = true;
  layer.load(0, {time: 1000 * 60 * 60 * 24 * 365})
  return new Promise((resolver) => resolver(true));
}

const uploaded = (e) => {
  const data = JSON.parse(e.data);
  if (data.code == -2){
    logout(true);
    localStorage.removeItem("access_token")
    editLayer.value = false;
    return ;
  }
  layer.closeAll()
  pdf.path = data.data;
  layer.msg("文件已上传")
}

const uploadError = () => {
  layer.closeAll()
  layer.msg("文件上传失败，请稍后重试")
}

const newCodeReport = () => {
  reset()
  editLayer.value = !editLayer.value;
  isPreview.value = false;
  nextTick(() => {
    noInputRef.value.focus();
  })
}

const searchDT = () => {
  change(page);
}

const exportCodeImg = () => {
  layer.msg("二维码导出中...", { time: 1000 })
  let canvas = document.getElementById("qrcode").children[0];
  let atag = document.createElement('a');
  atag.href = canvas.toDataURL('image/png');
  atag.download = pdf.manufacturer + "-" + pdf.name;
  atag.click();
}

const viewReport = () => {
  layer.open({
    type: "iframe",
    title: "查看报告",
    area: ['90%','90%'],
    content: ORIGIN + "load/" + pdf.no
  })
}

const reportUploadAction = ref([
  {
    text: "提交",
    callback: () => {
      if (pdf.path == ''){
        layer.msg("文件没有上传")
        return;
      }
      formRef.value.validate((isValidate) => {
        if (isValidate){
          axios.post(isEdit.value ? ORIGIN + 'update/code/report' : ORIGIN + 'add/code/report', pdf)
              .then(res=> {
                if (res.data.code == 0){
                  editLayer.value = !editLayer.value;
                  selectCurrentData(res.data.data)
                  setTimeout(() => {
                    readOnlyLayer.value = true
                    change(page)
                  }, 1000)
                }

              });
          isEdit.value = false;
        }
      });
    }
  },
  {
    text: "取消",
    callback: () => {
      editLayer.value = !editLayer.value;
      isEdit.value = false;
      reset()
    }
  }
])

const columns = [
  {
    title:"序号",
    width:"50px",
    key:"idx",
    fixed: "left",
  },
    {
    title:"序号",
    width:"50px",
    key:"id",
    fixed: "left",
    hide: true
  },{
    title:"出厂编号",
    width: "70px",
    key:"no",
  },{
    title:"柜体名称",
    width: "100px",
    key:"name",
    ellipsisTooltip: true,
  },{
    title:"生产厂名称",
    width: "140px",
    key:"manufacturer",
    ellipsisTooltip: true,
  },{
    title:"检验日期",
    width: "50px",
    key:"test_date",
  },{
    title:"二维码",
    width: "30px",
    customSlot: "code",
    key: "code",
  },{
    title:"备注",
    width: "150px",
    key:"remark",
    ellipsisTooltip: true,
  },{
    title:"创建日期",
    width: "70px",
    key:"create_time",
  },{
    title:"操作",
    width: "50px",
    customSlot: "operator",
    key: "operator",
  }
]

const dataSource = ref([])

const change = (paged) => {
  loading.value = true;
  axios.get(`${ORIGIN}code/report/list?pageNum=${paged.current}&pageSize=16&no=${search.no}&company=${search.manufacturer}&test_date=${search.test_date}`)
      .then(res=> {
        if (res.data.code == 0){
            res.data.data.list.map((item, i) => {
              item.idx = (paged.current - 1) * 16 + i + 1
            })
            dataSource.value = res.data.data.list;
            page.total = res.data.data.total;
        }
      }).finally(function () {
        setTimeout(() => loading.value = false , 100);
  });
}

change(page);

const {seconds} = useClock();

provide("seconds", seconds);

const {height} = useSize();

const autoHeight = computed(() => {
  return (height.value - 141) + "px";
});

const fullHeight = computed(() => {
  return height.value + "px";
});

</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.layui-tab-brief>.layui-tab-head>.layui-tab-title > .layui-this{
  color: #1e9fff !important;
}
.layui-tab-active-bar{
  background-color: #1e9fff !important;
}
.layui-body{
  min-height: 0 !important;
}
.layui-footer{
  line-height: 40px;
}
/*.layui-tab-content{*/
/*  display: none;*/
/*}*/

.login-bg{
  background: #efefef;
  margin-top: -41px;
}
.lay-icon{
  font-size: 12px !important;
}

.layui-quote{
  border-right: 5px solid var(--block-default-color);
}
#url{
  color: rgb(30, 159, 255);
}

</style>
