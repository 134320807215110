<template>
  <div style="width: 100%;height: 40px;line-height:40px; background-color: #1e9fff; color: #FFF;font-size: 16px;letter-spacing: 2px;overflow: hidden">
    <lay-row>
      <lay-col md="6">
        <div class="top-bar-bg">
          {{title}}
        </div>
      </lay-col>
      <lay-col md="12">
        <lay-notice-bar v-if="false" leftIcon="layui-icon-notice" mode="link" text="网络连接失败，无数据响应，请稍后重试。"></lay-notice-bar>
        <div v-else>&nbsp;</div>
      </lay-col>
      <lay-col md="6">
        <div style="text-align: right">
          <BarClock/>
          <lay-button prefix-icon="layui-icon-user"  type="normal" style="padding: 0 3px 0 0;" @click="logout">用户注销</lay-button>
         </div>
      </lay-col>
    </lay-row>
  </div>
</template>

<script setup>
import {defineProps, defineEmits} from "vue";
import BarClock from "@/components/BarClock";
import {layer} from "@layui/layui-vue";
defineProps(['title'])
const emits = defineEmits(['logout'])
const logout = () => {
  const layerId = layer.confirm(
      "退出登录?",
      {
              title: "",
              move: false,
              closeBtn: false,
              btn: [
                  {
                    text:'确认',
                    callback: ()=>{
                        layer.close(layerId);
                        localStorage.removeItem("access_token");
                        emits('logout', true);
                    }
                    },
                  {
                    text:'取消',
                    callback: ()=>{
                      layer.close(layerId)
                      emits('logout', false);
                    }
                  }]
            })

}
</script>
<style scoped>
.top-bar-bg{
  background: #1e9fff url('../assets/logo.png')  no-repeat 4px center ;
  background-size: 25px;
  text-indent: 35px;
}

</style>