<template>
  <lay-button  prefix-icon="layui-icon-time" type="normal" style="color: #FFF;margin-left: 0px; padding: 0 ;cursor: default">&nbsp;<span>{{seconds}}</span></lay-button>
</template>

<script setup>

import {inject} from "vue";

const seconds = inject('seconds');

</script>

<style scoped>

</style>