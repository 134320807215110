import {computed, onMounted, onUnmounted, ref} from "vue";
import dayjs from "dayjs";

export function useClock(){

    let timerId = null;
    const time = ref(new Date())

    const seconds = computed(() => {
        let tmp = time.value;
        return dayjs(tmp).format('YYYY-MM-DD HH:mm:ss');
    });

    onMounted(() => {
        timerId = setInterval(() => {
            time.value = new Date();
        }, 1000);
    })

    onUnmounted(() => {
        clearInterval(timerId);
    })

    return {seconds}
}