import {reactive} from "vue";

export function useUtils(){
    const copyright = 'Copyright © 2023 青岛升平电气有限公司'
    const title = '出厂报告管理系统'
    const version = 'v1.0'
    const ORIGIN = "https://rpt.spepi.cn/report/";
    const loginInfo = reactive({
        username:'',
        password: ''
    })
    return {copyright, title, version, ORIGIN, loginInfo}
}